<template>
<!--  text.id ? 'multiline' :  -->
  <div :class="text.id ? 'multiline' : 'multiline x_default' ">
    <span class="multiline_text" :style="'-webkit-line-clamp:' + line ">
      {{ text.text }}
    </span>
    <span class="multiline_more" @click="onclick" v-if="more">更多</span>
  </div>
</template>
<script>
export default {
  name: "Multiline",
  // 文本 行数
  props: ["text", 'line', 'more'],
  mounted() {},
  methods: {
    onclick(){
      this.$emit('clickevent')
    }
  },
};
</script>
<style lang="stylus" scoped>
@import './common.styl';

.multiline {
  position: relative;
  margin: 12px auto 10px;
  width: $viewWidth;
  background: #f4f4f4;
  color: #666666;
  border-radius: 5px;
  font-size: 13px;
  padding: 6px;
  display: flex;
  flex-direction: column;
}

.multiline_text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  word-break: break-all;
  white-space: normal;
  line-height 20px;
  letter-spacing: 0.5px
}

.multiline_more {
  font-size: 13px;
  color: #ff0101;
  margin-right 4px
  align-self: flex-end;
}
</style>