<template>
  <!--  v-if="lists[0] != ''" -->
  <div :class="lists[0] != '占位占位占位' ? 'words' : 'words x_default'">
    <span class="words_title">{{title}}</span>
    <div class="words_view">
      <div v-for="(item,index) in lists" :key="index" style="margin: 4px 0">
        <!--  v-if="index < line" -->
        <span class="words_text" v-html="item"></span>
      </div>
      <!-- <div class="words_more" @click="onclick" v-if="lists.length > line">更多</div> -->
    </div>
  </div>
</template>
<script>
export default {
  name: 'Words',
  // 标题 列表 默认显示行数
  props: ['title', 'lists', 'line'],
  data() {
    return {
      words_more: true
    }
  },
  mounted() {
  },
  methods: {
    onclick() {
      this.$emit('clickevent')
    }
  }
}
</script>
<style lang="stylus" scoped>
@import './common.styl';

.words
  margin 0 auto
  width $viewWidth
  background $themeWhite
  border-radius 5px
  padding 10px 10px 20px 10px
.words_title
  font-size 16px
  font-weight 600
.words_view
  margin-top 6px
  position relative
.words_text
  color $themeColor
  font-size 14px
.words_more
  font-size 13px
  color #ff0101
  position absolute
  bottom 0
  right 20px
</style>