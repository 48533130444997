<template>
  <div :class="item.id ? 'gift' : 'gift x_default'">
    <div class="gift_content">
      <span class="gift_title">{{item.name}}</span>
      <span class="gift_text">{{item.des}}</span>
      <div class="gift_surplus">
        <span class="gift_capacity">
          <span class="gift_current" :style="'width:' + currentGift + '%'"></span>
        </span>
        <span>剩余{{ currentGift }}%</span>
      </div>
    </div>
    <span class="gift_button" @click="onclick(item)" v-if="item.receive == '0'">领取</span>
    <span class="gift_button" @click="onlook(item)" v-else>查看</span>
  </div>  
</template>
<script>
export default {
  props: ['item'],
  data() {
    return {
      currentGift: 0
    }
  },
  mounted() {
    this.currentGift = (Number(this.item.num) / Number(this.item.count) * 100).toFixed(2)
  },
  methods: {
    onclick(item) {
      this.$emit('clickevent', item)
    },
    onlook(item) {
      this.$emit('clicklook', item)
    }
  }
}
</script>
<style lang="stylus" scoped>
@import './common.styl';
.gift
  width $viewWidth
  background $themeWhite
  margin 0 auto
  border-radius 5px
  height 94px
  display flex
  flex-direction row
  align-items center
  justify-content space-between
  padding 0 14px
.gift_content
  display flex
  flex-direction column
  width 82%
.gift_title
  font-size 14px
  font-weight 600
  width 70vw
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
.gift_text
  font-size 12px
  color $themeColor
  margin 2px 0 8px
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
.gift_surplus
  display flex
  align-items center
  font-size 12px
  color #a0a0a0
.gift_capacity
  width 50%
  height 4px
  border-radius 2px
  background #a0a0a0
  margin-right 4px
  display block
.gift_current
  height 100%
  border-radius 2px
  background $themeMax
  display block
.gift_button
  height 24px
  line-height 24px
  width 54px
  text-align center
  font-size 16px
  color #fff
  background linear-gradient(90deg, $themeMin 30%, $themeMax 100%)
  border-radius 12px
</style>